
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import GetAppIcon from '@material-ui/icons/GetApp';
import { lsSetItem, lsGetItem } from '../functions/ls';
import beautify from 'js-beautify'
import TurndownService from 'turndown'
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { isFileInDropbox } from '../functions/dropbox';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';

import packageJson from '../../package.json';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    textfield: {
        width: '100%',
    },
    form: {
        // borderLeft: '2px solid #9e9e9e'
        padding: '1rem 3rem'
    },
    textfieldColor: {
        "& input": {
            width: '8rem',
            height: '4rem'
        }
    },
    boxPreset:{
        backgroundColor: 'red',
        color:'green',
        textAlign: 'center',
        fontWeight: 'bold',
        height: '4rem',
        width: '4rem',
        borderRadius: '0.5rem',
        verticalAlign: 'middle',
        fontSize: '2.5rem'
    }
}));

export default function ConfigurationContent(props) {
    
    const classes = useStyles();
    const [titre, setTitre] = useState(props.content.titre);
    const [synopsis, setSynopsis] = useState(props.content.synopsis);

    const [objQuotidien, setObjQuotidien] = useState(props.content.objectifs_quotidien);
    const [objHebdomadaire, setObjHebdomadaire] = useState(props.content.objectifs_hebdomadaire);
    const [objMensuel, setObjMensuel] = useState(props.content.objectifs_mensuel);
    const [objGlobal, setObjGlobal] = useState(props.content.objectifs_global);

    const [editorWidthLS, setEditorWidthLS] = useState(parseInt(lsGetItem('lioshi_editor_width')) || 50);
    const [editorFontSizeLS, setEditorFontSizeLS] = useState(parseInt(lsGetItem('lioshi_editor_fontsize')) || 5);
    const [editorFontFamilyLS, setEditorFontFamilyLS] = useState(lsGetItem('lioshi_editor_fontfamily') || 'sans-serif');

    const [editorBgColorLS, setEditorBgColorLS] = useState(lsGetItem('lioshi_editor_bg_color') || '#303030');
    const [editorFontColorLS, setEditorFontColorLS] = useState(lsGetItem('lioshi_editor_font_color') || '#c5c8c6');

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [isTitreFieldError, setIsTitreFieldError] = useState(false);

    const [freqSaveMinutes, setFreqSaveMinutes] = useState(props.content.freq_save_minutes);
    
    const handleChangeFontsizeSlider = (event, newvalue) => {
        console.log('fontsize-slider change: '+newvalue)
        props.setEditorFontSize(newvalue);
        setEditorFontSizeLS(newvalue);
    }

    const handleChangeWidthSlider = (event, newvalue) => {
        console.log('width-slider change: '+newvalue)
        props.setEditorWidth(newvalue);
        setEditorWidthLS(newvalue);
    }

    const handleChangeFontFamilySelect = (event) => {
        console.log('fontfamily-select change: '+event.target.value)
        props.setEditorFontFamily(event.target.value);
        setEditorFontFamilyLS(event.target.value);
    }

    const handleChangeEditorBgColor = (event) => {
        console.log('Bg color change: '+event.target.value)
        props.setEditorBgColor(event.target.value);
        setEditorBgColorLS(event.target.value);
    }

    const handleChangeEditorFontColor = (event) => {
        console.log('Font color change: '+event.target.value)
        props.setEditorFontColor(event.target.value);
        setEditorFontColorLS(event.target.value);
    }
    const preset = [
        { // jour
            "color": "black",
            "bgcolor": "#FFFFFF"
        },
        { // nuit
            "color": "#AAAAAA",
            "bgcolor": "#000000"
        },
        { // sepia
            "color": "#AB9882",
            "bgcolor": "#FFFAE0"
        },
        { // pénombre
            "color": "#C5C6C8",
            "bgcolor": "#303030"
        }
    ]
    const presetChange = (e) => {
        console.log('presetChange: '+e.currentTarget.getAttribute('data-preset-id'));
        let presetId = e.currentTarget.getAttribute('data-preset-id');
        props.setEditorBgColor(preset[presetId]['bgcolor']);
        setEditorBgColorLS(preset[presetId]['bgcolor']);
        props.setEditorFontColor(preset[presetId]['color']);
        setEditorFontColorLS(preset[presetId]['color']);
    }
    //console.log(preset)

    const handleSubmitConfig = () => {
        props.content.titre=titre;
        props.content.synopsis=synopsis;
        props.content.objectifs_quotidien=objQuotidien;
        props.content.objectifs_hebdomadaire=objHebdomadaire;
        props.content.objectifs_mensuel=objMensuel;
        props.content.objectifs_global=objGlobal;
        props.content.freq_save_minutes=freqSaveMinutes;

        lsSetItem('lioshi_editor_width', editorWidthLS);
        lsSetItem('lioshi_editor_fontsize', editorFontSizeLS);
        lsSetItem('lioshi_editor_fontfamily', editorFontFamilyLS);

        lsSetItem('lioshi_editor_font_color', editorFontColorLS);
        lsSetItem('lioshi_editor_bg_color', editorBgColorLS);
    
        props.handleSave();
    }

    const handleDownloadLocalDataToHtml = () => {
        toast.info('Sauvegarde au format Html en cours...',{"autoClose": true});
        var data = props.content;
        const a = document.createElement('a');
        
        var style = "<style> \
            @page\
            { \
                size: A4; \
                margin: 30mm 40mm;  \
            } \
            h1 {\
                text-align: center;\
                margin-top: 35vh;\
            }\
            h2 {\
                break-before: right;\
                text-align: center;\
                margin-top: 35vh;\
            }\
            h3 {\
                break-before: right;\
                margin-top: 25vh;\
                text-align: center;\
            }\
            h4 {\
                text-align: center;\
            }\
            blockquote {\
                border-left: 0.2em solid;\
                padding: 0em 0.5em;\
                font-style: italic;\
            }\
            p {\
                text-align: justify;\
            }\
        </style>";

        // format dataText
        var dataTextReplaced = data.text
        dataTextReplaced = dataTextReplaced.replace(/<.*?><\/.*?>/ig,''); // remove empty tag
        dataTextReplaced = dataTextReplaced.replace(/<blockquote.*?>.*?<\/blockquote>/ig,'');
        dataTextReplaced = dataTextReplaced.replace(/<h4.*?>.*?<\/h4>/ig,'<h4>***</h4>');
        // <blockquote>"+data.synopsis+"</blockquote>
        var datas = beautify.html(style+"<h1>"+data.titre+"</h1>"+dataTextReplaced, {
            "indent_size": "4",
            "indent_char": " ",
            "max_preserve_newlines": "5",
            "preserve_newlines": true,
            "keep_array_indentation": false,
            "break_chained_methods": false,
            "indent_scripts": "normal",
            "brace_style": "collapse",
            "space_before_conditional": true,
            "unescape_strings": false,
            "jslint_happy": false,
            "end_with_newline": false,
            "wrap_line_length": "0",
            "indent_inner_html": false,
            "comma_first": false,
            "e4x": false,
            "indent_empty_lines": false
          });

        const file = new Blob([datas], {type: "text/html"});
        a.href= URL.createObjectURL(file);
        a.download = data.titre;
        a.click();
        URL.revokeObjectURL(a.href);
    }

 
    const handleDownloadLocalDataToMd = () => {
        toast.info('Sauvegarde au foramt Markdown en cours...',{"autoClose": true});
        var data = props.content;
        const a = document.createElement('a');

        var turndownService = new TurndownService()
        var datas = turndownService.turndown("<h1>"+data.titre+"</h1><blockquote>"+data.synopsis+"</blockquote><hr/>"+data.text);

        const file = new Blob([datas], {type: "text/markdown"});
        a.href= URL.createObjectURL(file);
        a.download = data.titre+".markdown";
        a.click();
        URL.revokeObjectURL(a.href);
    }

    const handleDownloadLocalDataToPdf = () => {
        // toast.info('To do ');
        var data = props.content;
        var opt = {
            margin:       [30, 40, 30, 40], 
            filename:     data.titre,
            html2canvas:  { scale: 4 },
            jsPDF:        { format: 'a4', orientation: 'portrait' },
            // pagebreak: { mode: 'css' }
        };
        var style = "<style> \
            h2 {\
                break-before: right;\
            }\
            h3 {\
                break-before: right;\
            }\
            h1,h2,h3,p,blockquote { \
                page-break-inside: avoid !important; \
            } \
        </style>";


        var content = style+"<div style='color:black;'><h1>"+data.titre+"</h1><blockquote>"+data.synopsis+"</blockquote>"+data.text+"</div>";
          
        // New Promise-based usage:
        // html2pdf().set(opt).from(content).save();

        html2pdf().from(content).set(opt).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    //pdf.setTextColor(150);
                    //pdf.text('Page ' + i + ' of ' + totalPages, 10, 10);
                    console.log(pdf.internal.pageSize.getHeight());
                    //pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth()/2.25,(pdf.internal.pageSize.getHeight()-8));
                    pdf.text( ''+i+'' , pdf.internal.pageSize.getWidth()/2,(pdf.internal.pageSize.getHeight()-8));
                }
        }).save();



        // html2pdf("<h1>hello world</h1>")    ;

        // var data = props.content;
        // const a = document.createElement('a');

        // var turndownService = new TurndownService()
        // var datas = turndownService.turndown("<h1>"+data.titre+"</h1><blockquote>"+data.synopsis+"</blockquote><hr/>"+data.text);

        // const file = new Blob([datas], {type: "text/markdown"});
        // a.href= URL.createObjectURL(file);
        // a.download = data.titre+".markdown";
        // a.click();
        // URL.revokeObjectURL(a.href);
    }
    

    return(
        <>
        <Grid container justifyContent="center" spacing={0} className={classes.form}>
            

            {/* <Grid container justifyContent="center" spacing={0} className={classes.form}> */}
                {/* <Grid item xs={12}>
                    <Typography component="div" variant="h6" gutterBottom>Informations</Typography>
                </Grid> */}
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        color="default"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                        onClick={handleDownloadLocalDataToHtml}
                    >
                        .html
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        color="default"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                        onClick={handleDownloadLocalDataToMd}
                    >
                        .markdown
                    </Button>
                </Grid>
                {/* <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color="default"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                        onClick={handleDownloadLocalDataToPdf}
                    >
                        .pdf
                    </Button>
                </Grid> */}
            {/* </Grid> */}

            <Box margin={1}></Box>
            
            
            <Grid item xs={12}>
                <Typography component="div" variant="h6" gutterBottom>Informations</Typography>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        id="titre"
                        variant="outlined"
                        label="Titre"
                        type="text"
                        value={titre}
                        onChange={(e) => {
                                // console.log('props.dropboxFiles');
                                // console.log(props.dropboxFiles);
                                if(isFileInDropbox(e.target.value+'.json', props.dropboxFiles, props.content)){
                                    console.warn('Filename exists !!!!!!!!!!')
                                    setBtnDisabled(true);
                                    setIsTitreFieldError(true);
                                } else {
                                    console.warn('Filename doesnt exists....')
                                    setBtnDisabled(false);
                                    setIsTitreFieldError(false);
                                }
                                setTitre(e.target.value);
                            }
                        }    // TODO : verify filename allready exists
                        className={classes.textfield}
                        helperText={isTitreFieldError?"Ce fichier existe déjà":""}
                        error={isTitreFieldError}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        id="synopsis"
                        variant="outlined"
                        label="Synopsis"
                        type="text"
                        multiline
                        value={synopsis}
                        onChange={(e) => setSynopsis(e.target.value)}
                        className={classes.textfield}
                    />
                </Grid>
            

                <Grid item xs={12}></Grid>


                <Grid item xs={12}>
                    <Typography component="div" variant="h6" gutterBottom>Objectifs</Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField 
                    id="quotidien"
                    variant="outlined"
                    label="Quotidien"
                    type="number"
                    value={objQuotidien}
                    onChange={(e) => setObjQuotidien(e.target.value)}
                    className={classes.textfieldObj}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                    id="hebdomadaire"
                    variant="outlined"
                    label="Hebdomadaire"
                    type="number"
                    value={objHebdomadaire}
                    onChange={(e) => setObjHebdomadaire(e.target.value)}
                    className={classes.textfieldObj}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                    id="mensuel"
                    variant="outlined"
                    label="Mensuel"
                    type="number"
                    value={objMensuel}
                    onChange={(e) => setObjMensuel(e.target.value)}
                    className={classes.textfieldObj}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                    id="global"
                    variant="outlined"
                    label="Global"
                    type="number"
                    value={objGlobal}
                    onChange={(e) => setObjGlobal(e.target.value)}
                    className={classes.textfieldObj}
                    />
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Typography component="div" variant="h6" gutterBottom>Affichage</Typography>
                </Grid>

                <Grid item xs={6}>
                    {/* <TextField 
                    id="editorWidthLS"
                    label="Largeur de l'éditeur (en %)"
                    type="number"
                    inputProps={{ min: "20", max: "100", step: "1" }}
                    value={editorWidthLS}
                    onChange={(e) => setEditorWidthLS(e.target.value)}
                    className={classes.textfield}
                    /> */}
                    <Typography id="width-slider" gutterBottom>
                        Largeur de l'éditeur
                    </Typography>
                    <Slider
                        key="width-slider"
                        valueLabelDisplay="auto"
                        // getAriaValueText="jkhkjhkhj"
                        aria-labelledby="width-slider"
                        step={5}
                        marks
                        min={20}
                        max={100}
                        value={editorWidthLS}
                        // value={parseInt(editorFontSizeLS) || 5}
                        onChange={handleChangeWidthSlider}
                        // onChangeCommitted={handleChangeCommittedFontsizeSlider}
                    
                    />
                </Grid>

                <Grid item xs={6}>
                    {/* <TextField 
                    id="editorFontsize"
                    label="Taille de la police"
                    type="number"
                    inputProps={{ min: "0", max: "10", step: "1" }}
                    defaultValue={parseInt(lsGetItem('lioshi_editor_fontsize')) || 5}
                    onChange={(e) => {
                            props.setEditorFontSize(e.target.value);
                            lsSetItem('lioshi_editor_fontsize', e.target.value);
                        }
                    }
                    className={classes.textfield}
                    /> */}
                    <Typography id="fontsize-slider" gutterBottom>
                        Taille de la police
                    </Typography>
                    <Slider
                        key="fontsize-slider"
                        valueLabelDisplay="auto"
                        // getAriaValueText="jkhkjhkhj"
                        aria-labelledby="fontsize-slider"
                        step={1}
                        marks
                        min={1}
                        max={10}
                        value={editorFontSizeLS}
                        // value={parseInt(editorFontSizeLS) || 5}
                        onChange={handleChangeFontsizeSlider}
                        // onChangeCommitted={handleChangeCommittedFontsizeSlider}
                    
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel id="demo-simple-select-helper-label">Nom de la police</InputLabel>
                    <Select
                    labelId="font-family"
                    id="editor-font-family"
                    value={editorFontFamilyLS}
                    onChange={handleChangeFontFamilySelect}
                    >
                        <MenuItem value={'monospace'}>Monospace</MenuItem>
                        <MenuItem value={'sans-serif'}>Sans-serif</MenuItem>
                        <MenuItem value={'serif'}>Serif</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12}>
                    <Typography component="div" variant="h6" gutterBottom>Couleurs</Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField 
                    id="editorBgColor"
                    variant="outlined"
                    label="Fond"
                    type="color"
                    value={editorBgColorLS}
                    onChange={handleChangeEditorBgColor}
                    className={classes.textfieldColor}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField 
                    id="editorFontColor"
                    variant="outlined"
                    label="Police"
                    type="color"
                    value={editorFontColorLS}
                    onChange={handleChangeEditorFontColor}
                    className={classes.textfieldColor}
                    />
                </Grid>



                <Grid item xs={12}>
                    <Typography component="div" variant="h6" gutterBottom>Pré-selections</Typography>
                </Grid>

                <Grid item xs={3}>
                    <Button data-preset-id="0" onClick={presetChange} variant="contained" color="primary" disableElevation className={classes.boxPreset} m={1} style={{"color":preset[0]["color"],"backgroundColor":preset[0]["bgcolor"]}}>
                        a
                    </Button>
                    
                </Grid>
                <Grid item xs={3}>
                    <Button data-preset-id="1" onClick={presetChange} variant="contained" color="primary" disableElevation className={classes.boxPreset} m={1} style={{"color":preset[1]["color"],"backgroundColor":preset[1]["bgcolor"]}}>
                            a
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button data-preset-id="2" onClick={presetChange} variant="contained" color="primary" disableElevation className={classes.boxPreset} m={1} style={{"color":preset[2]["color"],"backgroundColor":preset[2]["bgcolor"]}}>
                            a
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button data-preset-id="3" onClick={presetChange} variant="contained" color="primary" disableElevation className={classes.boxPreset} m={1} style={{"color":preset[3]["color"],"backgroundColor":preset[3]["bgcolor"]}}>
                            a
                    </Button>
                </Grid>




                {/* // TODO : put sliders : https://material-ui.com/components/slider/#slider
                // ERROR : https://stackoverflow.com/questions/64175786/warning-failed-prop-type-invalid-prop-value-supplied-to-forwardrefslider */}


                <Grid item xs={12}>
                    <Typography component="div" variant="h6" gutterBottom>Sauvegarde automatique</Typography>
                </Grid>

                <Grid item xs={5}>
                    {/* <TextField 
                    id="saveminutes"
                    fullWidth
                    label="Fréquence de sauvegarde"
                    type="number"
                    inputProps={{ min: "1", max: "30", step: "1" }}
                    value={freqSaveMinutes}
                    onChange={(e) => setFreqSaveMinutes(e.target.value)}
                    className={classes.textfieldObj}
                    /> */}
                    <OutlinedInput
                        // autoFocus
                        id="newfilename"
                        fullWidth
                        value={freqSaveMinutes}
                        onChange={(e) => setFreqSaveMinutes(e.target.value)}
                        endAdornment={<InputAdornment position="end">minutes</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        type="number"
                        inputProps={{ min: "1", max: "30", step: "1" }}
                        labelWidth={0}
                    />
                </Grid>     
            

                <Grid item xs={12}></Grid>

                
                <Grid item xs={12} align="center">
                    <Button id="savebuttonConfiguration" startIcon={<SaveIcon  />} variant="outlined" disableElevation disabled={btnDisabled} onClick={() => { handleSubmitConfig(); }}>
                        Sauvegarder
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={12} align="right">
                <Chip label={packageJson.version} disabled variant="outlined" />
            </Grid>
            
        </Grid>


        {/* <Grid container justifyContent="center" spacing={2} >
            <Grid item xs={12}>
                <Typography component="div" variant="h6" gutterBottom><br/></Typography>
            </Grid>
        </Grid> */}

        
        </>
    )

}


// "titre": "Titre : consectetur adipiscing elit",
//     "synopsis": "Synopsis : consectetur adipiscing elit. Phasellus ac ipsum elementum, bibendum ligula a, lobortis elit. Donec viverra, tortor vitae condimentum aliquet, risus ante conseqdf uat nisi, et suscipit nisl justo a quam. Aenean euismod ultrices ultricies. Pellentesque blandit arcu ut tellus tincidunt tristique. Aenean nulla mauris, congue vel tincidunt tincidunt, faucibus eu nisi. Pellentesque sagittis ut nisi et ultricies. Morbi nec suscipit erat. Nulla porttitor mi sit amet metus maximus ornare. Maecenas a turpis sit amet dui lacinia pellentesque. Etiam convallis, ex id vestibulum blandit, leo dui vestibulum ligula, in placerat felis diam id nibh.",
//     "objectifs": {
//         "quotidien": 1500,
//         "hebdomadaire": 3000,
//         "mensuel": 12000,
//         "global": 60000
//     },