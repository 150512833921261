import React from 'react';
import { Editor as EditorDraft, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './textbox.css';
import TextboxToolbar from './TextboxToolbar'

export default function Textbox(props) {

    const keyBindingFn = (event) => {
        props.setSaved(false)
        return getDefaultKeyBinding(event);
    };

    const onEditorChange = (editorState) => {
        props.setEditorState(editorState);
    }

    return (
        <Grid
            container
            spacing={0}
            style={{
                width: "100%",
                // marginTop: "2em"
            }}
            justifyContent="center"
        >
            <Grid item style={{
                width: "calc("+props.editorWidth+"vw - 2em)",
                // marginTop: "2em"
            }}
                // xs={10}
                // sm={9}
                // md={8}
                // lg={6}
                // xl={4}
            >
                <Typography component="span" variant="body1" align="left" color="textSecondary"
                    style={{
                        fontSize: (props.editorFontSize)*0.2+"rem",
                        color: props.editorFontColor,
                        fontFamily: props.editorFontFamily
                    }} 
                > 
                    <TextboxToolbar 
                        displayTextboxToolbar={props.displayTextboxToolbar} 
                        handleBlockClick={props.handleBlockClick} 
                        editorState={props.editorState} 
                        setEditorState={props.setEditorState} 
                        EditorState={props.EditorState} 
                        handleSave={props.handleSave} 
                        saved={props.saved} 
                        setSaved={props.setSaved} 
                        onEditorChange={onEditorChange}
                    />
                    <EditorDraft 
                        editorState={props.editorState} 
                        onChange={onEditorChange} 
                        keyBindingFn={keyBindingFn}
                    />
                </Typography>
            </Grid>
        </Grid>

    )
}

