import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toc from './Toc'
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import TocIcon from '@material-ui/icons/Toc';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InfosContent from './InfosContent';
import ConfigurationContent from './ConfigurationContent';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
    menuButton: {
        "& button": {
            left: '1rem',
            position: 'fixed',
            background: '#303030',
            bottom: '1rem',
            zIndex: 1201,
            opacity: 0.7,
            color: '#C5C6C8'
        },
        "& button:hover": {
            background: '#303030',
            opacity: 1,
        }
    },
    toolbarSwitch: {
        "& button": {
            right: '1rem',
            position: 'fixed',
            background: '#303030',
            bottom: '1rem',
            zIndex: 1201,
            opacity: 0.7,
            color: '#C5C6C8'
        },
        "& button:hover": {
            background: '#303030',
            opacity: 1,
        }
    },
    infoButtonHidden: {
        visibility: 'hidden',
        height: 0
    },
    toolbar: {
        background: '#303030',
        boxShadow: 'none'
    },
    title: {
        flexGrow: 1,
    },
    dialogtitle: {
        padding: 1
    },
    button: {
        color: theme.palette.text.secondary
    }
}));



export default function Settings(props) {
    const classes = useStyles();
    const [toolbarShow, setToolbarShow] = useState(false);
    const handleChange = (event) => {
        if(!toolbarShow){
            props.handleTextboxToolbarOpen();
            setToolbarShow(true);
        } else {
            props.handleTextboxToolbarClose();
            setToolbarShow(false);
        }
    };

    const updateToc = () => {
        console.log('launch updateToc')
        // get headers hierarchy
        let parts = []
        const contentState = props.editorState.getCurrentContent()
        const entityKeys = contentState.getBlockMap()
        // get map of draft Js
        entityKeys.forEach(element => {
            if(element.getType()==="header-two"){
                parts.push({
                    "label":element.getText(),
                    "level": 1,
                    "key": element.getKey()
                })
            }
            if(element.getType()==="header-three"){
                parts.push({
                    "label":element.getText(),
                    "level": 2,
                    "key": element.getKey()
                })
            }
            if(element.getType()==="header-four"){
                parts.push({
                    "label":element.getText(),
                    "level": 3,
                    "key": element.getKey()
                })
            }
        });
        console.log(parts);
        // set toc to update
        props.setToc(parts)
    }
    
    const [openToc, setOpenToc] = React.useState(false);
    const handleDrawerTocOpen = () => {
        updateToc();
        setOpenToc(true);
    };
    const handleDrawerTocClose = () => {
        setOpenToc(false);
    };

    const [openSettings, setOpenSettings] = React.useState(false);
    const handleDrawerSettingsOpen = () => {
        handleDrawerTocClose();
        setOpenSettings(true);
    };
    const handleDrawerSettingsClose = () => {
        setOpenSettings(false);
    };

    const [openInfos, setOpenInfos] = React.useState(false);
    const handleDrawerInfosOpen = () => {
        handleDrawerTocClose();
        setOpenInfos(true);
    };
    const handleDrawerInfosClose = () => {
        setOpenInfos(false);
    };

    return (
        <div className={classes.root}>
            <div className={classes.menuButton}>
                <IconButton size="medium" color="default" aria-label="toc" onClick={handleDrawerTocOpen}>
                    <TocIcon />
                </IconButton>
            </div>

            {/* only for onClick */}
            <div className={classes.infoButtonHidden}> 
                <IconButton size="medium" id="settingsButton" edge="start" color="default" aria-label="settings" onClick={handleDrawerSettingsOpen}>
                    <InfoIcon />
                </IconButton>
            </div>

            <div className={classes.infoButtonHidden}> 
                <IconButton size="medium" id="infosButton" edge="start" color="default" aria-label="settings" onClick={handleDrawerInfosOpen}>
                    <InfoIcon />
                </IconButton>
            </div>

            <div className={classes.toolbarSwitch}>
                {toolbarShow?
                    <IconButton size="medium" color="default" aria-label="toolbar" onClick={handleChange}>
                        <CloseIcon />
                    </IconButton>
                    :
                    <IconButton size="medium" color="default" aria-label="toolbar" onClick={handleChange}>
                        <MenuIcon />
                    </IconButton>
                }
            </div>

            <Drawer anchor="left" open={openToc} onClose={handleDrawerTocClose} className="drawertoc">
                <Typography component="span" variant="body1" align="left" color="textSecondary">
                    <Box margin={2}>
                        <Typography variant="h5" align="center">
                            {props.content.titre}
                        </Typography>
                            
                        {/* <Typography color="textSecondary" variant="body2" align="center">
                            {props.content.synopsis}
                        </Typography> */}
                    </Box>
                    
                    <Toc toc={props.toc} content={props.content}/>
                    
                </Typography>
            </Drawer>

            <Drawer anchor="right" open={openSettings} onClose={handleDrawerSettingsClose} className="drawersettings">
                {/* <SettingsTabs 
                    content={props.content} 
                    handleSave={props.handleSave} 
                    setEditorFontSize={props.setEditorFontSize} 
                    setEditorWidth={props.setEditorWidth}
                    setEditorFontColor={props.setEditorFontColor}
                    setEditorBgColor={props.setEditorBgColor} 
                    dropboxFiles={props.dropboxFiles}
                /> */}
                <ConfigurationContent 
                    content={props.content} 
                    handleSave={props.handleSave} 
                    setEditorFontSize={props.setEditorFontSize} 
                    setEditorFontFamily={props.setEditorFontFamily} 
                    setEditorWidth={props.setEditorWidth}
                    setEditorFontColor={props.setEditorFontColor}
                    setEditorBgColor={props.setEditorBgColor} 
                    dropboxFiles={props.dropboxFiles}
                />
            </Drawer>


            <Drawer anchor="right" open={openInfos} onClose={handleDrawerInfosClose} className="drawerinfos">
                <InfosContent content={props.content} handleSave={props.handleSave} editorState={props.editorState}/>
            </Drawer>



        </div>
    );
}






  

