// import './App.css';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import grey from '@material-ui/core/colors/grey';
import Editor from './components/Editor'
import CssBaseline from '@material-ui/core/CssBaseline';

import 'moment/locale/fr';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './components/toast.css';

import Login from './components/Login';

import { Alert } from '@material-ui/lab';

import { lsGetItem, lsIsSupport } from './functions/ls';

import './App.css';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    fullscreenicon: {
        position: 'fixed',
        right: '0.5em',
        bottom: '4em',
        opacity: 0.3
    },
    alert: {
        margin: '2em'
    }
}));



const LOCAL_STORAGE_ITEM = "lioshi_editor_content";

function App() {


    // const [showExitPrompt, setShowExitPrompt] = useState(false);
    // window.onload = function() {
    //     initBeforeUnLoad(showExitPrompt);
    // };
    // const handleShowExitPrompt = () => {
    //     setShowExitPrompt(!showExitPrompt)
    // }

    // useEffect(() => {
    //     initBeforeUnLoad(showExitPrompt);
    //     //lsIsSupport();
    // }, [showExitPrompt]);

    const [editorBgColor, setEditorBgColor] = useState(lsGetItem('lioshi_editor_bg_color') || '#303030');
    const theme = createTheme({
        palette: {
            type: 'dark',
            primary: {
                main: grey[400],
                light: grey[200],
            },
            warning: {
                main: '#f0c674',
                light: '#f7e2b9',
            },
            error: {
                main: '#cc6666',
                light: '#df9f9f',
            },
            info: {
                main: '#81a2be',
                light: '#b3c7d8',
            },
            success: {
                main: '#b5bd68',
                light: '#cfd49d',
            },
            text: {
                primary: '#c5c8c6',
                secondary: '#c5c8c6',
                disabled: '#c5c8c6',
                hint: '#c5c8c6',
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*': {
                        'scrollbar-width': 'thin',
                    },
                    '*::-webkit-scrollbar': {
                        width: '4px',
                        height: '4px',
                    },
                    "*::-webkit-scrollbar-track": {
                        backgroundColor: editorBgColor
                    },
                    "*::-webkit-scrollbar-thumb": {
                        backgroundColor: '#666'
                    }
                }
            },
            // MuiButton: {
            //     raisedPrimary: {
            //         color: 'red',
            //     },
            // },
          }
    });

    const classes = useStyles();
    const [content, setContent] = useState('');
    const [dropboxFiles, setDropboxFiles] = useState([]);

    // Pcloud pop up for oauth connection
    // pcloudInitOAuth();
    

    

    return (
        // <Typography component="span" variant="body1" align="left" color="textSecondary">
        <>
            {lsIsSupport()?
                <>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        
                        {content?
                            <Editor 
                                content={content}
                                setContent={setContent}
                                lsItemName={LOCAL_STORAGE_ITEM}
                                dropboxFiles={dropboxFiles}
                                editorBgColor={editorBgColor}
                                setEditorBgColor={setEditorBgColor}
                            />
                        :
                            <>
                                {/* <div className={classes.root}>
                                    <LinearProgress />
                                </div> */}
                                <Login 
                                    setContent={setContent} 
                                    lsItemName={LOCAL_STORAGE_ITEM}
                                    dropboxFiles={dropboxFiles}
                                    setDropboxFiles={setDropboxFiles}
                                />
                            </>
                        } 
                    
                    </ThemeProvider>
                </>
                :
                <ThemeProvider theme={theme}>
                    <Alert variant="filled" severity="error" className={classes.alert}>
                        {/* <AlertTitle> */}
                            Votre navigateur n'est pas compatbile.
                        {/* </AlertTitle> */}
                    </Alert>
                </ThemeProvider>
            }

            <ToastContainer
                closeButton={false}
                theme="colored"
                limit={3}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
       
        </>
        // </Typography>
    );
}

export default App;





