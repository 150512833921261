import { toast } from 'react-toastify';

export function lsIsSupport(){
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch(e){
        toast.error('LocalStorage non supporté')
        return false;
    }
}


export function lsSetItem(name, value){
    try {
        localStorage.setItem(name, value); 
      } catch (e) {
        if (e.code === "22" || e.code === "1024") {
            toast.error('LocalStorage quota dépassé')
        }
      }
}

export function lsGetItem(name){
    try {
        return localStorage.getItem(name); 
      } catch (e) {
        if (e.code === "22" || e.code === "1024") {
            toast.error('LocalStorage quota dépassé')
        }
      }
}