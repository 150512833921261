import React, { useState, useEffect } from 'react';
import { isFileInDropbox, parseQueryString } from '../functions/dropbox';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import moment from 'moment'
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import Divider from '@material-ui/core/Divider';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ReactComponent as DropboxLogo } from './Dropbox-logo.svg';
import { ReactComponent as EditorLogo } from '../docs/editor.svg';
import content_template from '../content_template'
import { lsSetItem, lsGetItem } from '../functions/ls';
import { digestMessage, ab2str } from '../functions/crypto';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Dropbox, DropboxAuth } from 'dropbox'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import packageJson from '../../package.json';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    card: {
        // maxWidth: 275,
        padding: 0
    },
    divider: {
        margin: '0',
        width: '100%'
    },
    cardActions: {
        textAlign: "right"
    },
    textfield: {
        width: '100%',
    },
    section: {
        margin: theme.spacing(3, 2),
    },
    buttonFile: {
        color: theme.palette.text.secondary,
        padding: 0
    },
    dropboxLogo: {
        width: '32px',
        height: '32px',
    },
    dropboxLogoFiles: {
        width: '16px',
        height: '16px',
    },
    editorLogo: {
        width: '7rem',
        height: '7rem',
        margin: '1rem'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    alert: {
        width: '100%'
    },
    cardError:{
        backgroundColor: theme.palette.info.main
    },
    buttonExit: {
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        opacity: 0.3
    },
    editorVersion : {
        position: 'fixed',
        bottom: '1rem',
        right: '2rem'
    }
}));

const SECONDS_CONSIDERING_LIKE_NO_UPDATE=10



export default function Login(props) {
    const classes = useStyles();

    const [dropboxConnectLoading, setDropboxConnectLoading] = useState(false);
    const [dropboxConnected, setDropboxconnected] = useState(false);
    // const [dropboxToken, setDropboxToken] = useState();
    

    const REDIRECT_URI = window.location.origin;
    const CLIENT_ID = 'uc2mrepanwnodyo';
    const dbxAuth = new DropboxAuth({
        clientId: CLIENT_ID,
    });

    const getCodeFromUrl = () => {
        return parseQueryString(window.location.search).code;
    }

    const handleDropboxConnect = () => {
        // simple oAuth2
        // var dbx = new Dropbox({ clientId: "uc2mrepanwnodyo" });
        // dbx.auth.getAuthenticationUrl(document.location.href)
        // .then((authUrl) => {
        //     //console.log(authUrl);
        //     window.location.href=authUrl;
        // })
  
        // pkce login
        setDropboxConnectLoading(true);
        dbxAuth.getAuthenticationUrl(REDIRECT_URI, undefined, 'code', 'offline', undefined, undefined, true)
        .then(authUrl => {
            window.sessionStorage.clear();
            window.sessionStorage.setItem("lioshi_editor_code_verifier", dbxAuth.codeVerifier);
            window.location.href = authUrl;
            //setDropboxConnectLoading(false);
        })
        .catch((error) => console.error(error));
    }

    const handleDropboxDisconnect = () => {
        window.location.reload();
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleNewDocument = () => {
        handleClose();
        setDropboxConnectLoading(true);
        if(!localData){
            const filename = document.getElementById('newfilename').value;
            // //console.log('new filename : '+filename);
            content_template.titre=filename
            props.setContent(content_template);
        } else {
            toast.error('Le fichier local "'+localData.titre+'.json" va être écrasé. Supprimez ou sauvegardez le avant.');
            setDropboxConnectLoading(false);
        }
    }


    const handleUseLocalData = () => {
        
        //console.log(lsGetItem('lioshi_editor_content_lastsavedate'));
        //console.log(localData.titre);
        //console.log(props.dropboxFiles)

        // check if file exist in dropbox and not modified since lastLocal saving
        let dateIfFileExistsInDropbox = isFileInDropbox(localData.titre+'.json', props.dropboxFiles, false, true)
        if(dateIfFileExistsInDropbox){
            const lastLocalSave = moment(lsGetItem('lioshi_editor_content_lastsavedate'));
            const lastLocalSaveDate = lastLocalSave.format("DD/MM/YYYY HH:mm:ss");
            const fileExistsInDropboxLastModified = moment(dateIfFileExistsInDropbox);
            const fileExistsInDropboxLastModifiedDate = fileExistsInDropboxLastModified.format("DD/MM/YYYY HH:mm:ss")

            
            // lastLocalSave  fileExistsInDropboxLastModified
            var duration = moment.duration(lastLocalSave.diff(fileExistsInDropboxLastModified));
            var seconds = duration.asSeconds();
            // //console.log(Math.abs(seconds))
            if(Math.abs(seconds)>SECONDS_CONSIDERING_LIKE_NO_UPDATE){
                toast.error(
                    <div>
                        Le fichier local <b>{localData.titre}.json</b> existe sur Dropbox avec des dates disparates :<br/>
                        <ul>
                            <li>Fichier local: {lastLocalSaveDate}</li>
                            <li>Fichier sur Dropbox: {fileExistsInDropboxLastModifiedDate}</li>
                        </ul>
                    </div>,
                    {autoClose: false}
                )
            } else {
                toast.success(
                    <div>
                        Le fichier local <b>{localData.titre}.json</b> est chargé et existe sur Dropbox <br/><br/>
                        Dates de modifications <br/>
                        <ul>
                            <li>Fichier local: {lastLocalSaveDate}</li>
                            <li>Fichier sur Dropbox: {fileExistsInDropboxLastModifiedDate}</li>
                        </ul>
                    </div>,
                    {autoClose: 5000}
                )
                props.setContent(localData);
            }
        } else {
            props.setContent(localData);
        }
    }

    // const [fileToDownload, setFileToDownload] = useState('');
    const handleDownloadDropboxFile = (e) => {
        
        const dataFileInfos = e.target.closest('a').getAttribute('data-infos');

        let filePath = dataFileInfos.split('|')[0];
        let fileModifiedDate = dataFileInfos.split('|')[1];


        // setFileToDownload(filePath);
        //console.log("download file : "+filePath);

        dbxAuth.setAccessToken(window.sessionStorage.getItem("lioshi_editor_access_token"));
        var dbx = new Dropbox({
            auth: dbxAuth
        });
        dbx.filesDownload({path: filePath})
        .then(function(response) {
            
            // read file
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                let fileString = event.target.result;
                try {
                    let data=JSON.parse(fileString);
                    //console.log(data);
                    if(checkData(data)){
                        // is localData
                        if(localData){
                            //console.log(localData)
                            if(localData.titre === data.titre){ // même fichier local et chargement
                                //console.log(data);
                                digestMessage(JSON.stringify(data))
                                .then( res => {
                                    return ab2str(res);
                                })
                                .then( HashContentToOpen => {
                                    //console.log(HashContentToOpen)
                                    //console.log(localData);
                                    digestMessage(JSON.stringify(localData))
                                    .then( res => {
                                        let HashContentLocal = ab2str(res);
                                        //console.log(HashContentLocal)
                                        return {HashContentToOpen, HashContentLocal};
                                    })
                                    .then(({HashContentToOpen,HashContentLocal}) => {
                                        //console.log(HashContentToOpen)
                                        //console.log(HashContentLocal)

                                        const lastlocalsave = moment(lsGetItem('lioshi_editor_content_lastsavedate'));
                                        const fileToOpenLastModified = moment(fileModifiedDate);
                                        //console.log(lastlocalsave.format("DD/MM/YYYY HH:mm:ss"));
                                        //console.log(fileToOpenLastModified.format("DD/MM/YYYY HH:mm:ss"));
                                        
                                        if(HashContentToOpen !== HashContentLocal){
                                            toast.error(
                                                <div>
                                                    Le fichier local <b>{localData.titre}.json</b> n'a pas le même contenu que celui que vous souhaitez charger, merci de vérifier lequel est le bon.<br/>
                                                    Vous aurez alors un choix :<br/>
                                                    <ul>
                                                        <li>Supprimer le fichier local afin de charger un nouveau fichier</li>
                                                        <li>Charger le fichier local et le sauvegarder</li>
                                                    </ul>
                                                    Dates de modifications :
                                                    <ul>
                                                        <li>Fichier local : {lastlocalsave.format("DD/MM/YYYY HH:mm:ss")}</li>
                                                        <li>Fichier à charger : {fileToOpenLastModified.format("DD/MM/YYYY HH:mm:ss")}</li>
                                                    </ul>
                                                </div>,
                                                {autoClose: false})
                                        } else {
                                            toast.info(
                                                <div>
                                                    Le fichier Dropbox <b>{localData.titre}.json</b> est chargé.<br/>
                                                    Il a exactement le même contenu que le fichier local.<br/>
                                                    Date de modification : {fileToOpenLastModified.format("DD/MM/YYYY HH:mm:ss")}
                                                </div>,
                                                {autoClose: 5000})
                                            props.setContent(data);
                                        }  
                                    })
                                })
                            } else {
                                toast.error('Le fichier local "'+localData.titre+'.json" va être écrasé. Supprimer le avant.')
                            }
                        } else {
                            props.setContent(data);
                        }
                    } else {
                        toast.error('Mauvais format de fichier');
                    }
                    
                } catch(e) {
                    toast.error('Mauvais format de fichier');
                }
            });
            reader.readAsText(response.result.fileBlob);
   
        })
        .catch(function(error) {
            toast.error('Souci de connexion à Dropbox.');
            setTimeout(function(){ window.location.reload(); }, 3000);

            console.error(error);
        });
    }

    const checkData = (data) => {
        if(
            data && typeof data != 'undefined' &&
            data.titre && typeof data.titre != 'undefined' &&
            data.synopsis && typeof data.synopsis != 'undefined' &&
            data.objectifs_quotidien && typeof data.objectifs_quotidien != 'undefined' &&
            data.objectifs_hebdomadaire && typeof data.objectifs_hebdomadaire != 'undefined' &&
            data.objectifs_mensuel && typeof data.objectifs_mensuel != 'undefined' &&
            data.objectifs_global && typeof data.objectifs_global != 'undefined' &&
            data.text && typeof data.text != 'undefined' &&
            data.history && typeof data.history != 'undefined'
            ){
                return true;
            } else {
                return false;
            }
    }

    const [localDataButton, setLocalDataButton] = useState(false);
    const [localData, setLocalData] = useState();

    useEffect(() => {
        
        // get code on url
        let dropboxCodeViaUrl = getCodeFromUrl();
        if(dropboxCodeViaUrl){
            setDropboxConnectLoading(true);
            dbxAuth.setCodeVerifier(window.sessionStorage.getItem("lioshi_editor_code_verifier"));
            dbxAuth.getAccessTokenFromCode(REDIRECT_URI, dropboxCodeViaUrl)
                .then((response) => {
                    dbxAuth.setAccessToken(response.result.access_token);
                    window.sessionStorage.setItem("lioshi_editor_access_token", response.result.access_token);
                    // setDropboxconnected(true);
                    // setDropboxConnectLoading(false);
                    window.history.replaceState({}, document.title, "/");
                    var dbx = new Dropbox({
                        auth: dbxAuth
                    });
                    return dbx.filesListFolder({
                        path: ''
                    });
                })
                .then((response) => {
                    //console.log(response.result.entries);
                    props.setDropboxFiles(response.result.entries);
                    setDropboxconnected(true);
                    setDropboxConnectLoading(false);
                })
                .catch((error) => {
                    console.error(error)
                });
        } else {
            window.sessionStorage.clear();
        }

        if(lsGetItem(props.lsItemName)){
            global.lsdata = JSON.parse(lsGetItem(props.lsItemName));
            if(checkData(global.lsdata)){
                console.log('local data exists')

                // TODO : test if file has same data inside, and dont display if same data
                // TODO : display only if data is differents


                setLocalDataButton(true);
                setLocalData(global.lsdata);
                //props.setContent(data);

                if(dropboxConnected) {
                    // pcloudListfiles()
                    // .then(res =>{
                    //     if(res.severity=='success'){
                    //         // toast.success(res.message);
                    //     } else {
                    //         toast.error(res.message);
                    //     }
                    //     // display files to load
                    //     //console.log(typeof res.files)
                    //     //console.log(res.files)
                        
                    //     setPcloudFiles(res.files)
                        
                    //     return res.files;
                        
                    // })
                    // .then(pcloudFilesList => {
                    //     // test if local file exists in pcloud
                    //     pcloudFilesList.map((file, i) => { 
                    //         if(file.name == global.lsdata.titre){
                    //             const lastLocalSave = moment(lsGetItem('lioshi_editor_content_lastsavedate', "DD/MM/YYYY HH:mm:ss"));
                    //             const lastLocalSaveDate = lastLocalSave.format("DD/MM/YYYY HH:mm:ss");
                    //             const lastPcloudFileSave = moment(file.modified);
                    //             const lastPcloudFileSaveDate = lastPcloudFileSave.format("DD/MM/YYYY HH:mm:ss")

                    //             // lastLocalSave  lastPcloudFileSave
                    //             var duration = moment.duration(lastLocalSave.diff(lastPcloudFileSave));
                    //             var seconds = duration.asSeconds();
                    //             // //console.log(Math.abs(seconds))
                    //             if(Math.abs(seconds)>10){
                    //                 toast.error(
                    //                     <div>
                    //                         Le fichier local <b>{global.lsdata.titre}</b> existe sur PCloud <br/><br/>
                    //                         Dates de modifications <br/>
                    //                         <ul>
                    //                             <li>Fichier local: {lastLocalSaveDate}</li>
                    //                             <li>Fichier sur PCloud: {lastPcloudFileSaveDate}</li>
                    //                         </ul>
                    //                     </div>,
                    //                     {autoClose: false}
                    //                 )
                    //             } else {
                    //                 toast.success(
                    //                     <div>
                    //                         Le fichier local <b>{global.lsdata.titre}</b> existe sur PCloud <br/><br/>
                    //                         Dates de modifications <br/>
                    //                         <ul>
                    //                             <li>Fichier local: {lastLocalSaveDate}</li>
                    //                             <li>Fichier sur PCloud: {lastPcloudFileSaveDate}</li>
                    //                         </ul>
                    //                     </div>,
                    //                     {autoClose: 15000}
                    //                 )
                    //             }

                                
                    //         }
                    //     })
                    // })
                } 


            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);



    const handleRemoveLocalData = () => {
        if (window.confirm('Souhaitez-vous supprimer le contenu local : "'+localData.titre+'.json" ?')) {
            //console.log('remove local data')
            lsSetItem(props.lsItemName,'');
            props.setContent('');
            setLocalDataButton(false);
            setLocalData('');
        }
    }

    const handleDownloadLocalData = () => {
        // console.log(lsGetItem(props.lsItemName));
        // var data = JSON.parse(lsGetItem(props.lsItemName));
        // var fileName = data.titre;
        // // Create a blob of the data
        // var fileToSave = new Blob([JSON.stringify(data)]);
        
        // // Save the file
        // saveAs(fileToSave, fileName);

        var data = JSON.parse(lsGetItem(props.lsItemName));

        const a = document.createElement('a');
        const file = new Blob([lsGetItem(props.lsItemName)], {type: "application/json"});
        
        a.href= URL.createObjectURL(file);
        a.download = data.titre;
        a.click();

        URL.revokeObjectURL(a.href);


    }

    const [isNewFilenameFieldError, setIsNewFilenameFieldError] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

    const handleExit = () => {
        document.location.reload(); 
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            >
            <Grid item xs={12} align="right">
                <Chip label={packageJson.version} disabled className={classes.editorVersion} variant="outlined" />
            </Grid>

            <Grid item xs={12}>
                <div className={classes.section}>
                    {/* <Typography component="div" variant="h6" gutterBottom>Synchronisation PCloud</Typography> */}


                    <Grid item xs={12} align="center">
                        <EditorLogo className={classes.editorLogo}/>
                    </Grid>

                    

                    <Grid container justifyContent="center" spacing={2} className={classes.form}>
                        
                        {/* <Grid item xs={12}>
                            <TextField required id="pcloudClientId" label="Pcloud client ID" fullWidth onChange={(event) => { setPcloudClientId(event.target.value) }} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField required type="password" id="pcloudClientSecret" label="Pcloud client secret" fullWidth onChange={(event) => { setPcloudClientSecret(event.target.value) }} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField required type="text" id="pcloudFilename" label="Pcloud filename" fullWidth onChange={(event) => { setPcloudFilename(event.target.value) }} />
                        </Grid> */}


                        {/* <Grid item xs align="center">
                            <Chip
                                icon={<CloudIcon />}
                                variant="outlined"
                                label={<><Moment locale="fr" format="dddd DD/MM/YYYY HH:mm:ss">
                                {props.content.lastcloudsave}
                            </Moment></>}
                            />
                        </Grid> */}

                        {localDataButton && dropboxConnected?
                            <>
                                <IconButton size="medium" color="default" aria-label="toolbar" className={classes.buttonExit} onClick={handleExit}>
                                    <ExitToAppIcon />
                                </IconButton>
                                {/* <Alert variant="filled" severity="error" className={classes.alert}>
                                    <AlertTitle>
                                        Un document local existe : <strong>{localData.titre}</strong>
                                    </AlertTitle>
                                    <Moment locale="fr" format="dddd DD/MM/YYYY HH:mm:ss"> 
                                        {lsGetItem('lioshi_editor_content_lastsavedate')}
                                    </Moment>
                                        <Grid item xs={12} align="center">
                                            <Box component="span" m={1}></Box>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <Button variant="outlined" m={4} startIcon={<DescriptionIcon />}  disableElevation onClick={() => { handleUseLocalData(); }}>
                                                L'ouvrir 
                                            </Button>
                                            <Box component="span" m={1}></Box>
                                            <Button variant="outlined" startIcon={<DeleteIcon />}  disableElevation onClick={() => { handleRemoveLocalData(); }}>
                                                Le supprimer 
                                            </Button>
                                        </Grid>
                                    
                                </Alert> */}
                                <Divider variant="middle" className={classes.divider}/>
                                    
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" justifyContent="center" spacing={0}>
                                        <Grid item xs={12} align="center">

                                            {/* <Card variant="outlined" className={classes.cardError}> */}
                                                {/* <Box component="div" m={1}></Box> */}
                                                {/* <Typography component="div" variant="h6">Un document local existe : <strong>{localData.titre}</strong></Typography>   */}

                                                <Box component="div" m={1}>
                                                    Un document local existe : <strong>{localData.titre}.json</strong><br/>
                                                    <Moment locale="fr" format="dddd DD/MM/YYYY HH:mm:ss"> 
                                                        {lsGetItem('lioshi_editor_content_lastsavedate')}
                                                    </Moment>
                                                </Box>
                                                
                                                

                                                <List component="nav" dense={true}>
                                                    <ListItem button component="a" key="0" onClick={handleUseLocalData}>
                                                        <ListItemIcon>
                                                            <DescriptionOutlinedIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="L'ouvrir"
                                                            secondary=""
                                                        />
                                                    </ListItem>
                                                    <ListItem button component="a" key="1" onClick={handleRemoveLocalData}>
                                                        <ListItemIcon>
                                                            <DeleteIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Le supprimer"
                                                            secondary=""
                                                        />
                                                    </ListItem>
                                                    <ListItem button component="a" key="2" onClick={handleDownloadLocalData}>
                                                        <ListItemIcon>
                                                            <GetAppIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Le télécharger"
                                                            secondary=""
                                                        />
                                                    </ListItem>
                                                </List> 

                                                {/* <Button variant="outlined" m={4} startIcon={<DescriptionIcon />}  disableElevation onClick={() => { handleUseLocalData(); }}>
                                                            L'ouvrir 
                                                        </Button>
                                                        <Box component="span" m={1}></Box>
                                                        <Button variant="outlined" startIcon={<DeleteIcon />}  disableElevation onClick={() => { handleRemoveLocalData(); }}>
                                                            Le supprimer 
                                                        </Button> */}


                                            {/* </Card> */}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* <Box component="span" m={1}></Box> */}
                                <Divider variant="middle" className={classes.divider}/>

                                {/* <Alert variant="filled" severity="error" className={classes.alert}>zzzzz</Alert>
                                <Alert variant="filled" severity="info" className={classes.alert}>zzzzz</Alert>
                                <Alert variant="filled" severity="success" className={classes.alert}>zzzzz</Alert>  */}
                                {/* <Divider variant="middle" className={classes.divider}/> */}
                            </>
                            :
                            <></>
                        }


                        <Grid item xs={12} align="center">
                            {dropboxConnectLoading?
                                <CircularProgress color="inherit" fontSize="medium"/>
                                :
                                <>
                                    {dropboxConnected?
                                        <>
                                            {/* <div></div> */}
                                            <Grid container alignItems="center" justifyContent="center" spacing={4}>
                                                {/* <Grid item xs={12} align="center">
                                                    <Button variant="outlined" disableElevation onClick={handleNewDocument}>Créer un nouveau fichier</Button>
                                                    <Box component="span" m={1}></Box>
                                                </Grid> */}
                                                <Grid item xs={12} align="center">
                                                    

                                                    




                                                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                                        <DialogTitle id="form-dialog-title">Créer un nouveau fichier</DialogTitle>
                                                        <DialogContent>
                                                        {/* <DialogContentText>
                                                            To subscribe to this website, please enter your email address here. We will send updates
                                                            occasionally.
                                                        </DialogContentText> */}
                                                        
                                                        {/* <TextField 
                                                            autoFocus
                                                            id="newfilename"
                                                            label="Nom du fichier"
                                                            type="text"
                                                            fullWidth
                                                            // value={titre}
                                                            onChange={(e) => {
                                                                    // //console.log('props.dropboxFiles');
                                                                    // //console.log(props.dropboxFiles);
                                                                    if(isFileInDropbox(e.target.value, props.dropboxFiles, false)){
                                                                        console.warn('Filename exists !!!!!!!!!!')
                                                                        setBtnDisabled(true);
                                                                        setIsNewFilenameFieldError(true);
                                                                    } else {
                                                                        console.warn('Filename doesnt exists....')
                                                                        setBtnDisabled(false);
                                                                        setIsNewFilenameFieldError(false);
                                                                    }
                                                                }
                                                            }    // TODO : verify filename allready exists
                                                            className={classes.textfield}
                                                            helperText={isNewFilenameFieldError?"Ce fichier existe déjà":""}
                                                            error={isNewFilenameFieldError}
                                                        />  */}


                                                        <FormControl variant="outlined">
                                                            <OutlinedInput
                                                                autoFocus
                                                                id="newfilename"
                                                                fullWidth
                                                                onChange={(e) => {
                                                                    // //console.log('props.dropboxFiles');
                                                                    // //console.log(props.dropboxFiles);
                                                                        if(isFileInDropbox(e.target.value+'.json', props.dropboxFiles, false)){
                                                                            console.warn('Filename exists !!!!!!!!!!')
                                                                            setBtnDisabled(true);
                                                                            setIsNewFilenameFieldError(true);
                                                                        } else {
                                                                            console.warn('Filename doesnt exists....')
                                                                            setBtnDisabled(false);
                                                                            setIsNewFilenameFieldError(false);
                                                                        }
                                                                    }
                                                                } 
          
                                                                endAdornment={<InputAdornment position="end">.json</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                'aria-label': 'weight',
                                                                }}
                                                                labelWidth={0}
                                                                error={isNewFilenameFieldError}
                                                            />
                                                            <FormHelperText id="outlined-weight-helper-text">
                                                                {isNewFilenameFieldError?<Typography component="span" variant="body2" color="error">Ce fichier existe déjà</Typography>:"Nom du fichier"}
                                                            </FormHelperText>
                                                        </FormControl>
                                                   


                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button variant="outlined" onClick={handleClose} color="primary">
                                                                Annuler
                                                            </Button>
                                                            <Button variant="outlined" disabled={btnDisabled} onClick={handleNewDocument} color="primary">
                                                                Créer le fichier
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>

                                                    {/* <Box component="span" m={4}></Box> */}

                                                    
                                                    

                                                    {/* <Card variant="outlined"> */}
                                                        <List component="nav" dense={true}>
                                                            <ListItem button component="a" key="0" onClick={handleClickOpen}>
                                                                <ListItemIcon>
                                                                    <InsertDriveFileOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary="Créer un nouveau fichier"
                                                                    secondary=""
                                                                />
                                                            </ListItem>
                                                        </List> 
                                                    {/* </Card> */}
                                                    <Divider variant="middle" className={classes.divider}/>
                                                    
                                                    
                                                    {/* <Card variant="outlined"> */}
                                                        {/* <Box component="div" m={1}></Box> */}
                                                        
                                                        {/* <Typography component="div" variant="h6">Ouvrir un fichier <DropboxLogo className={classes.dropboxLogoFiles}/></Typography>   */}

                                                        <Box component="div" m={2}>
                                                            <FormControl className={classes.formControl}>
                                                                <Button variant="outlined" startIcon={<DropboxLogo className={classes.dropboxLogo}/>}  disableElevation onClick={() => { handleDropboxDisconnect(); }}>
                                                                    Déconnexion
                                                                </Button>
                                                            </FormControl>
                                                        </Box>

                                                        <List component="nav" dense={true}>
                                                            {props.dropboxFiles.map((file, i) => {     
                                                                if(file.name.split('.').pop()==='json'){
                                                                    return (
                                                                        <ListItem data-infos={file.path_display+"|"+file.server_modified} button component="a" key={file.name} onClick={handleDownloadDropboxFile}>
                                                                            <ListItemIcon>
                                                                                <DescriptionOutlinedIcon />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={file.name}
                                                                                secondary={<><Moment locale="fr" format="dddd DD/MM/YYYY HH:mm:ss">
                                                                                {file.server_modified}
                                                                            </Moment></>}
                                                                            />
                                                                        </ListItem>
                                                                    )
                                                                } else {
                                                                    return("");
                                                                }
                                                            })
                                                            }
                                                        </List>
                                                    {/* </Card> */}

                                                    {/* <Box component="div" m={2}></Box> */}
                                                    

                                                    

                                                    {/* <FormControl className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Fichier Dropbox</InputLabel>
                                                        <Select
                                                            native
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={fileToDownload}
                                                            onChange={handleDownloadDropboxFile}
                                                            label="Fichier"
                                                        >
                                                            <option key="0" value="0">-- Nouveau fichier --</option>
                                                            {props.dropboxFiles.map((file, i) => {     
                                                                return (
                                                                    <option key={file.name} value={file.path_display+"|"+file.server_modified}>{file.name}</option>
                                                                ) 
                                                            })}
                                                        </Select>
                                                    </FormControl> */}
                                                </Grid>

                                                    

                                                
                                            </Grid>
                                            <Grid container alignItems="center" justifyContent="center" spacing={0}>

                                            
                                            </Grid> 
                                        </>
                                        :
                                        <Grid container alignItems="center" justifyContent="center" spacing={4}>
                                            {/* <Grid item xs={12} align="center">
                                                <EditorLogo className={classes.editorLogo}/>
                                            </Grid> */}
                                            <Grid item xs={12} align="center">
                                                <Button
                                                    variant="outlined"
                                                    color="default"
                                                    className={classes.button}
                                                    startIcon={<DropboxLogo className={classes.dropboxLogo}/>}
                                                    onClick={() => { handleDropboxConnect(); }}
                                                    >
                                                    Connexion
                                                </Button>    
                                            </Grid>
                                        </Grid>
                                    } 
                                </>
                            }
                                    


                        </Grid>
                    </Grid>
                </div>


                
            </Grid>   
        </Grid>
    )
}