import React from "react";
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  itemPart: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    textTransform: 'uppercase',
    display:'flex', 
    justifyContent:'center',
    borderBottom: '1px solid #666666'
  },
  itemChapter: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    textTransform: 'capitalize',
    // display:'flex', 
    // justifyContent:'center',
    paddingLeft: '2em',
  },
  itemScene: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    textTransform: 'capitalize',
    fontStyle: 'italic',
    // display:'flex', 
    // justifyContent:'center',
    paddingLeft: '3em',
  },
}));

const handleClick = (key) => {
    console.log("--->"+key)
    document.querySelectorAll('[data-offset-key=\''+key+'-0-0\']')[0].scrollIntoView({behavior: "smooth"});
}

export default function TocItem(props) {
    const classes = useStyles();
    let classItem = null
    if(props.headerElement.level===1){
        classItem = classes.itemPart
    }
    if(props.headerElement.level===2){
        classItem = classes.itemChapter
    }
    if(props.headerElement.level===3){
        classItem = classes.itemScene
    }
    return (
        
            <ListItem button onClick={(event) => handleClick(props.headerElement.key, event)} className={classItem} >
                {props.headerElement.label}
            </ListItem>
    );
}


