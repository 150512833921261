import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment'

const options = {
    scales: {
        xAxes: {
            display: true,
            type: 'time',
            time: {
              parser: 'DD/MM/YYYY',
              tooltipFormat: 'DD/MM/YYYY',
              unit: 'day',
              unitStepSize: 1,
              displayFormats: {
                'day': 'DD/MM/YYYY'
              }
            },
            grid: {
                color: '#444'
            },
            ticks: {
                maxTicksLimit: 30
            }
        },
        yAxes: {
            grid: {
                color: '#444'
            },
            ticks: {
                maxTicksLimit: 30
            }
        }
    },
    plugins: {
        legend: {
            display: false,
            position: 'bottom'
        }
    }
};



export default function HistoryGraph(props) {
    const data = {
        //   labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
            {
              label: 'Nombre de mots',
              data: props.history,
              fill: false,
              backgroundColor: '#353535',
              borderColor: '#888',
              stepped: true,
            },
        ],
    };

    return (
        <>
            <Line data={data} options={options} />
        </>
    )
};

