import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as DropboxLogo } from './Dropbox-logo.svg';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    dropboxLogo: {
        width: '32px',
        height: '32px',
    }
}));

export default function DropboxLogoToast(props) {
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" align="center" direction="row">
            <Grid item xs={10} style={{textAlign: "center"}}>
                {props.message}
            </Grid>
            <Grid item xs={2} style={{textAlign: "center"}}>
                <DropboxLogo className={classes.dropboxLogo}/>
            </Grid>
        </Grid>
    )
}
