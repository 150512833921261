import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import HistoryGraph from './HistoryGraph';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: '1rem 3rem'
    },
    listItem: {
      padding: theme.spacing(0.5),
    },
    section: {
      margin: theme.spacing(4, 2),
    },
    sectionFirst: {
        margin: theme.spacing(2, 2),
    },
    progress: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    },
    hiddenChip: {
        opacity: 0
    },
    textfield: {
        width: '100%',
    },
}));

function CircularProgressWithLabel(props) {

    let formatedProps = props.value;
    if(props.value>100){
        formatedProps=100
    }

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress 
            size={80}
            thickness={6} 
            variant="determinate" 
            value={formatedProps} />
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <Typography 
            variant="caption" 
            component="div" 
            color="textSecondary"
            >
                {`${Math.round(props.value,)}%`}
            </Typography>
        </Box>
      </Box>
    );
}

export default function InfosContent(props) {
    const classes = useStyles();


    // "objectifs": {
    //     "quotidien": 1500,
    //     "hebdomadaire": 3000,
    //     "mensuel": 12000,
    //     "global": 60000
    // }

    let progressQuotidienObjective = '';
    let contentWordCountForLastDay = '';
    let lastDayDate = '';
    let progressHebdomadaireObjective = '';
    let contentWordCountForLastWeek = '';
    let weekDates = '';
    let progressMensuelObjective = '';
    let contentWordCountForLastMonth = '';
    let monthLabel = '';
    let progressGlobalObjective = '';
    let contentWordCount = '';


    console.log(typeof props.content.history)
    if(typeof props.content.history !== 'undefined' && props.content.history.length > 1){
        // var date
        const lastDay = props.content.history[props.content.history.length-1];
        const beforLastDay = props.content.history[props.content.history.length-2];

        // calcul des objectifs du jour
        contentWordCountForLastDay = lastDay.y - beforLastDay.y; // last day in history minus day before = word for last day
        lastDayDate = lastDay.x;
        progressQuotidienObjective = contentWordCountForLastDay/props.content.objectifs_quotidien*100;


        // calcul des objectifs de la semaine
        contentWordCountForLastWeek = contentWordCountForLastDay;
        for (let index = props.content.history.length-2; index > 0; index--) {
            if(moment(lastDay.x, "DD/MM/YYYY").isSame(moment(props.content.history[index].x, "DD/MM/YYYY"), 'week')){
                console.log("-- YES week --")
                contentWordCountForLastWeek += props.content.history[index].y - props.content.history[index-1].y;
            }
        }
        progressHebdomadaireObjective = contentWordCountForLastWeek/props.content.objectifs_hebdomadaire*100;
        const firstDayWeek = moment(lastDay.x, "DD/MM/YYYY").startOf('week');
        const lastDayWeek = moment(lastDay.x, "DD/MM/YYYY").endOf('week');
        weekDates = firstDayWeek.format("DD")+"-"+lastDayWeek.format("DD/MM/YYYY");


        // calcul des objectifs du mois
        contentWordCountForLastMonth = contentWordCountForLastDay;
        for (let index = props.content.history.length-2; index > 0; index--) {
            if(moment(lastDay.x, "DD/MM/YYYY").isSame(moment(props.content.history[index].x, "DD/MM/YYYY"), 'month')){
                contentWordCountForLastMonth += props.content.history[index].y - props.content.history[index-1].y;
                console.log("-- YES month -- "+contentWordCountForLastMonth)
            }
        }
        progressMensuelObjective = contentWordCountForLastMonth/props.content.objectifs_mensuel*100;
        monthLabel = moment(lastDay.x, "DD/MM/YYYY").locale('fr').format('MMMM YYYY');


        // calcul des objectifs global
        contentWordCount = props.content.history[props.content.history.length-1].y;
        progressGlobalObjective = contentWordCount/props.content.objectifs_global*100;
    }
    


    return(
        <div className={classes.root}>
            {/* <div className={classes.section}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography gutterBottom variant="h4">
                            {props.content.titre}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography color="textSecondary" variant="body2">
                    {props.content.synopsis}
                </Typography>
            </div> */}


            {/* <Divider variant="middle" /> */}


            

            {typeof props.content.history !== 'undefined' && props.content.history.length > 1?
                <div className={classes.sectionFirst}>
                    <Typography component="div" variant="h6" gutterBottom>Objectifs</Typography>
                    <Grid 
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        >
                        <Grid xs={12} sm={6} md={3} 
                            item
                        >
                            <div>
                                <Typography component="div" variant="body1" gutterBottom align="center" color="textSecondary">
                                    <CircularProgressWithLabel value={progressQuotidienObjective} />
                                </Typography>
                                <Typography variant="body1" gutterBottom align="center" color="textSecondary">
                                    Quotidien
                                </Typography>
                                <Typography component="div" variant="body2" gutterBottom align="center" color="textSecondary">
                                    {contentWordCountForLastDay} / {props.content.objectifs_quotidien}
                                </Typography>
                                <Typography component="div" variant="body2" gutterBottom align="center" color="textSecondary">
                                    <Chip label={lastDayDate} disabled variant="outlined" />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={6} md={3} 
                            item
                        >
                            <div>
                                <Typography component="div" variant="body1" gutterBottom align="center" color="textSecondary">
                                    <CircularProgressWithLabel value={progressHebdomadaireObjective}/>
                                </Typography>
                                <Typography variant="body1" gutterBottom align="center" color="textSecondary">
                                    Hebdomadaire
                                </Typography>
                                <Typography variant="body2" gutterBottom align="center" color="textSecondary">
                                    {contentWordCountForLastWeek} / {props.content.objectifs_hebdomadaire}
                                </Typography>
                                <Typography component="div" variant="body2" gutterBottom align="center" color="textSecondary">
                                    <Chip label={weekDates} disabled variant="outlined" />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={6} md={3} 
                            item
                        >
                            <div>
                                <Typography component="div" variant="body1" gutterBottom align="center" color="textSecondary">
                                    <CircularProgressWithLabel value={progressMensuelObjective}/>
                                </Typography>
                                <Typography variant="body1" gutterBottom align="center" color="textSecondary">
                                    Mensuel
                                </Typography>
                                <Typography variant="body2" gutterBottom align="center" color="textSecondary">
                                    {contentWordCountForLastMonth} / {props.content.objectifs_mensuel}
                                </Typography>
                                <Typography component="div" variant="body2" gutterBottom align="center" color="textSecondary">
                                    <Chip label={monthLabel} disabled variant="outlined" />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={6} md={3} 
                            item
                        >
                            <div>
                                <Typography component="div" variant="body1" gutterBottom align="center" color="textSecondary">
                                    <CircularProgressWithLabel value={progressGlobalObjective}/>
                                </Typography>
                                <Typography variant="body1" gutterBottom align="center" color="textSecondary">
                                    Global
                                </Typography>
                                <Typography variant="body2" gutterBottom align="center" color="textSecondary">
                                    {contentWordCount} / {props.content.objectifs_global}
                                </Typography>
                                <Typography component="div" variant="body2" gutterBottom align="center" color="textSecondary">
                                    <div className={classes.hiddenChip}>
                                        <Chip label="" disabled />
                                    </div>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                :
                <></>
            }

            {/* <Divider variant="middle" /> */}

            <div className={classes.section}>
                <Typography component="div" variant="h6" gutterBottom>Nombre de mots</Typography>
                <HistoryGraph history={props.content.history}/>
            </div>

            

        
        </div>
    )

}