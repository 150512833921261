export function isFileInDropbox(filename, dropboxFiles, contentLoad=false, getDateServerModified=false) {

    let isFileInDropbox = false;
    for(let i = 0; i < dropboxFiles.length; i++){
        if(contentLoad && filename === contentLoad.titre){
            continue;
        }
        if(filename === dropboxFiles[i].name){
            isFileInDropbox = true;
            if(getDateServerModified){
                isFileInDropbox=dropboxFiles[i].server_modified;
            }
        }
    }
    return isFileInDropbox;
}

export function parseQueryString(str) {
      const ret = Object.create(null);

      if (typeof str !== 'string') {
        return ret;
      }

      str = str.trim().replace(/^(\?|#|&)/, '');

      if (!str) {
        return ret;
      }

      str.split('&').forEach((param) => {
        const parts = param.replace(/\+/g, ' ').split('=');
        // Firefox (pre 40) decodes `%3D` to `=`
        // https://github.com/sindresorhus/query-string/pull/37
        let key = parts.shift();
        let val = parts.length > 0 ? parts.join('=') : undefined;

        key = decodeURIComponent(key);

        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : decodeURIComponent(val);

        if (ret[key] === undefined) {
          ret[key] = val;
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val);
        } else {
          ret[key] = [ret[key], val];
        }
      });

      return ret;
}






// const b64DecodeUnicode = (str) => {
//     // Going backwards: from bytestream, to percent-encoding, to original string.
//     return decodeURIComponent(atob(str).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));
// }
// export async function gitlabAPI(repoId, repoToken){ 
//     // lsGetItem('lioshi_editor_repo_id')
//     // lsGetItem('lioshi_editor_repo_token')

//     var urlGetDefaultBranch = 'https://gitlab.com/api/v4/projects/' + repoId; //+'/repository/files/content.json?ref=main';
//     let repoInfos = await fetch(encodeURI(urlGetDefaultBranch), {
//         method: 'get',
//         // mode: 'no-cors',
//         headers: new Headers({
//             'PRIVATE-TOKEN': repoToken,
//             'Content-Type': 'application/json',
//         })
//     })
//     .then((response) => {
//         if (!response.ok) {
//             return {
//                 'severity': 'error',
//                 'message': 'repo (HTTP '+response.status
//             }
//         } else {
//             return response.json();
//         }
//     })
//     .then(
//         (result) => {
//             if(typeof result.default_branch != 'undefined'){
//                 var url = 'https://gitlab.com/api/v4/projects/' + repoId +'/repository/files/content.json?ref='+result.default_branch;
//                 let repoDetails = fetch(encodeURI(url), {
//                     method: 'get',
//                     // mode: 'no-cors',
//                     headers: new Headers({
//                         'PRIVATE-TOKEN': repoToken,
//                         'Content-Type': 'application/json',
//                     })
//                 })
//                 .then((response) => {
//                     if (!response.ok) {
//                         // throw new Error("HTTP status " + response.status);
//                         return {
//                             'severity': 'error',
//                             'message': 'repo (HTTP '+response.status
//                         }
//                     } else {
//                         return response.json()
//                     }
//                 })
//                 .then(
//                     (result) => {
//                         let contentJson = JSON.parse(b64DecodeUnicode(result.content));
//                         console.log('1')





//                         return {
//                             'severity': 'success',
//                             'message': 'repo content received',
//                             'content': contentJson
//                         }
//                     },
//                     (error) => {
//                         return {
//                             'severity': 'error',
//                             'message': 'Bad content'
//                         }
//                     }
//                 )

//                 return repoDetails;
               
//             } else {
//                 return {
//                     'severity': 'error',
//                     'message': 'repo unknown'
//                 }
//             }
//         },
//         (error) => {
//             return {
//                 'severity': 'error',
//                 'message': 'repo not available'
//             }
//         }

        
//     )

//     return repoInfos;

  
// }






// if (lsGetItem('lioshi_editor_pcloud_id') && lsGetItem('lioshi_editor_pcloud_token')) {
    // if(!content && !isLoading){
    //     setIsLoading(true);
    //     // open document
    //     var urlGetDefaultBranch = 'https://gitlab.com/api/v4/projects/' + lsGetItem('lioshi_editor_pcloud_id'); //+'/pcloudsitory/files/content.json?ref=main';
    //     fetch(encodeURI(urlGetDefaultBranch), {
    //         method: 'get',
    //         // mode: 'no-cors',
    //         headers: new Headers({
    //             'PRIVATE-TOKEN': lsGetItem('lioshi_editor_pcloud_token'),
    //             'Content-Type': 'application/json',
    //         })
    //     })
    //     .then((response) => {
    //         if (!response.ok) {
    //             throw new Error("HTTP status " + response.status);
    //         } else {
    //             return response.json();
    //         }
    //     })
    //     .then(
    //         (result) => {
    //             console.log(result.default_branch);
    //             var url = 'https://gitlab.com/api/v4/projects/' + lsGetItem('lioshi_editor_pcloud_id')+'/pcloudsitory/files/content.json?ref='+result.default_branch;
    //             fetch(encodeURI(url), {
    //                 method: 'get',
    //                 // mode: 'no-cors',
    //                 headers: new Headers({
    //                     'PRIVATE-TOKEN': lsGetItem('lioshi_editor_pcloud_token'),
    //                     'Content-Type': 'application/json',
    //                 })
    //             })
    //                 .then((response) => {
    //                     if (!response.ok) {
    //                         throw new Error("HTTP status " + response.status);
    //                     } else {
    //                         return response.json();
    //                     }
    //                 })
    //                 .then(
    //                     (result) => {
    //                         let contentJson = JSON.parse(b64DecodeUnicode(result.content));
    //                         setContent(contentJson);
    //                         setIsLoaded(true);
    //                     },
    //                     (error) => {
    //                         setIsLoaded(false);
    //                         setError(error);
    //                         handlepcloudFormShow();
    //                     }
    //                 )
    //         },
    //         (error) => {
    //             setIsLoaded(false);
    //             setError(error);
    //             handlepcloudFormShow();
    //         }
    //     )
    // }
// } else {
//     // open pcloud form
//     handlepcloudFormShow()
// }