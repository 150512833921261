import React from "react";
import List from '@material-ui/core/List';
import TocItem from './TocItem';

export default function Toc(props) {
    return (
        <>
            <List>
                {props.toc.map((headerElement) => (
                    <TocItem 
                    headerElement={headerElement}
                    key={headerElement.key}
                    />
                ))}
            </List>
        </>
    );
}


