import React, { useState } from 'react';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import fscreen from 'fscreen';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const useStyles = makeStyles((theme) => ({
    toolbar: {
        // background: 'rgb(60 60 60 / 90%)',
        boxShadow: 'none',
        alignItems: 'center',
        zIndex: 1200,
        position: 'fixed',
        padding: '0em',
        right: '0.5rem',
        bottom: '4.2rem',
        width: '4rem',
        backgroundColor: 'rgb(48 48 48 / 75%)',
        borderRadius: '2rem',
        "@media (max-height: 770px)": {
            width: '8rem',
        },
        "@media (max-height: 440px)": {
            width: '12rem',
        },
        "@media (max-height: 320px)": {
            width: '16rem',
        },
        "@media (max-height: 250px)": {
            width: '20rem',
        }
    },
    toolbarHidden: {
        boxShadow: 'none',
        height: 0,
        width: 0,

    },
    button: {
        color: theme.palette.text.secondary,
        margin: '0.5rem',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
        }
    },
    buttonLetter: {
        color: theme.palette.text.secondary,
        margin: '0.5rem',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
        },
        "& span": {
            width: '24px',
            height: '24px'
        }
    },
    buttonHidden: {
        opacity: 0,
        width: 0,
        height:0,
        "& span": {
            width: '0px',
            height: '0px'
        }
    }

}));

export default function TextboxToolbar(props) {
    const classes = useStyles();
    const buttonOnStyle = {backgroundColor: "rgba(255, 255, 255, 0.08)"};
    const buttonOffStyle = {
        // backgroundColor: "rgba(255, 255, 255, 0)",
    };

    const getButtonStyle = (type) => {
        const currentSelection = props.editorState.getSelection();
        const currentKey = currentSelection.getStartKey();
        const currentBlock = props.editorState.getCurrentContent().getBlockForKey(currentKey);
        if(type===currentBlock.getType()){
            return buttonOnStyle;
        } else {
            return buttonOffStyle;
        }
    }

    const [isFscreen, setIsFscreen] = useState(false);
    const handlefs = () => {
        if(fscreen.fullscreenElement === null){
            fscreen.requestFullscreen(document.body);
            setIsFscreen(true)
        } else {
            fscreen.exitFullscreen();
            setIsFscreen(false)
        }
    }

    const handleExit = () => {
        document.location.reload();
    }

    return (
        <>
            {props.displayTextboxToolbar?
                <div className={classes.toolbar} >
                    <IconButton id="toolbar-header-two" onMouseDown={(e) => props.handleBlockClick(e, "header-two")} size="medium" className={classes.buttonLetter} style={getButtonStyle('header-two')}>P</IconButton>
                    <IconButton id="toolbar-header-three" onMouseDown={(e) => props.handleBlockClick(e, "header-three")} size="medium" className={classes.buttonLetter} style={getButtonStyle('header-three')}>C</IconButton>
                    <IconButton id="toolbar-header-four" onMouseDown={(e) => props.handleBlockClick(e, "header-four")} size="medium" className={classes.buttonLetter} style={getButtonStyle('header-four')}>S</IconButton>
                    <IconButton id="toolbar-blockquote" onMouseDown={(e) => props.handleBlockClick(e, "blockquote")} size="medium" className={classes.button} style={getButtonStyle('blockquote')}>
                        <FormatQuoteIcon/>
                    </IconButton>

                    <IconButton  
                        disabled={props.editorState.getUndoStack().size <= 0}
                        onMouseDown={() => {
                            props.setEditorState(props.EditorState.undo(props.editorState));
                            props.setSaved(false);
                            }
                        }
                        className={classes.button}
                        size="medium">
                        <UndoIcon/>
                    </IconButton>
                    <IconButton  
                        disabled={props.editorState.getRedoStack().size <= 0}
                        onMouseDown={() => {
                            props.setEditorState(props.EditorState.redo(props.editorState));
                            props.setSaved(false)
                            }
                        }
                        className={classes.button}
                        size="medium">
                        <RedoIcon/>
                    </IconButton>
                    
                    <IconButton
                        id="savebutton"
                        disabled={props.saved}
                        onClick={(e) => {e.preventDefault();props.handleSave();console.log('Toolbar Button Save press')}}
                        className={classes.button}
                        size="medium">
                        <SaveIcon/>
                    </IconButton>

                    <IconButton
                        id="settings+button"
                        onClick={(e) => {e.preventDefault();document.getElementById('settingsButton').click()}}
                        className={classes.button}
                        size="medium">
                        <SettingsIcon />
                    </IconButton>

                    <IconButton
                        id="settings+button"
                        onClick={(e) => {e.preventDefault();document.getElementById('infosButton').click()}}
                        className={classes.button}
                        size="medium">
                        <InfoIcon />
                    </IconButton>



                    {fscreen.fullscreenEnabled?
                        <span className={classes.fullscreenicon}>    
                            {!isFscreen?
                                <IconButton size="medium" className={classes.button} aria-label="fullscreen" onClick={handlefs}>
                                    <FullscreenIcon />
                                </IconButton>
                            :
                                <IconButton size="medium" className={classes.button} aria-label="fullscreenexit" onClick={handlefs}>
                                    <FullscreenExitIcon />
                                </IconButton>
                            }
                        </span>
                        :
                        <></>
                    }

                    <IconButton size="medium" color="default" aria-label="toolbar" className={classes.button} onClick={handleExit}>
                        <ExitToAppIcon />
                    </IconButton>

                </div>
                :
                <div className={classes.toolbarHidden} >
                    <IconButton // add buton hidden only for have the onClick... bad :-(
                        id="savebutton"
                        disabled={props.saved}
                        onClick={(e) => {e.preventDefault();props.handleSave();console.log('Toolbar Button Save press')}}
                        className={classes.buttonHidden}
                        size="medium">
                        <SaveIcon/>
                    </IconButton>
                </div>
                }
                {/* button to access */}
                {/* <IconButton
                    id="getCurrentBlockTypebutton"
                    disabled={props.saved}
                    onClick={(e) => {e.preventDefault(); props.getCurrentBlockType();}}
                    className={classes.button}
                    size="medium">
                    <SaveIcon/>
                </IconButton> */}
        </>
    )
}