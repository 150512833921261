const content_template = {
    "titre": "Consectetur adipiscing elit",
    "synopsis": "Phasellus ac ipsum elementum, bibendum ligula a, lobortis elit. Donec viverra, tortor vitae condimentum aliquet, risus ante conseqdf uat nisi, et suscipit nisl justo a quam. Aenean euismod ultrice Nulla porttitor mi sit amet metus maximus ornare. Maecenas a turpis sit amet dui lacinia pellentesque. Etiam convallis, ex id vestibulum blandit, leo dui vestibulum ligula, in placerat felis diam id nibh.",
    "objectifs_quotidien": 1500,
    "objectifs_hebdomadaire": 3000,
    "objectifs_mensuel": 12000,
    "objectifs_global": 60000,
    "text": "<h2>Partie 1</h2><blockquote>Synopsis Partie 1</blockquote><h3>Chapitre 1</h3><blockquote>Synopsis Chapitre 1</blockquote><h4>Scène 1</h4><blockquote>Synopsis Scène 1</blockquote><p>Contenu scène 1</p>",
    "history": []
}

export default content_template