import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertFromHTML, RichUtils } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import Box from '@material-ui/core/Box';
import Menu from './Menu'
import Textbox from './Textbox';
import DropboxLogoToast from './DropboxLogoToast'
import moment from 'moment'
import { toast } from 'react-toastify';
import { lsSetItem, lsGetItem } from '../functions/ls';
import { Dropbox } from 'dropbox'
import useExitPrompt from '../hooks/useExitPrompt.js'
import { makeStyles } from '@material-ui/core/styles';

const Editor = (props) => {

    // const [showExitPrompt, setShowExitPrompt] = 
    useExitPrompt(true);
    const [toc, setToc] = useState([]);
    
    // draft
    const [editorState, setEditorState] = useState(
        () => 
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(props.content.text.replace(/\\n/g, '<br/>'))
                    )
                )
            
    );

    const handleBlockClick = (e, blockType) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const [saved, setSaved] = useState(true);
    const handleSaved = () => {
        setSaved(true)
    }

    // affichage
    const [editorWidth, setEditorWidth] = useState(parseInt(lsGetItem('lioshi_editor_width')) || 50);
    const [editorFontSize, setEditorFontSize] = useState(parseInt(lsGetItem('lioshi_editor_fontsize')) || 5);
    const [editorFontFamily, setEditorFontFamily] = useState(lsGetItem('lioshi_editor_fontfamily') || 'sans-serif');

    // const [editorBgColor, setEditorBgColor] = useState(lsGetItem('lioshi_editor_bg_color') || '#303030'); // put it in app.js
    const [editorFontColor, setEditorFontColor] = useState(lsGetItem('lioshi_editor_font_color') || '#c5c8c6');

    const useStyles = makeStyles((theme) => ({
        container: {
            backgroundColor: props.editorBgColor,
            minHeight: '100vh'
        }
    
    }));
    const classes = useStyles();


    const handleSave = async () => {
        toast.info('Sauvegarde en cours...',{"autoClose": false});
        const data = stateToHTML(editorState.getCurrentContent(), {});

        // save text
        props.content.text = data

        // save history
        // [
        //     {"x": "03/01/2021", "y": 15}, 
        //     {"x": "04/01/2021", "y": 18},
        //     {"x": "04/02/2021", "y": 1},
        //     {"x": "05/05/2021", "y": 8}
        // ]
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if (dd < 10) { dd = '0' + dd;}
        if (mm < 10) { mm = '0' + mm; }
        let todayFormated = dd + '/' + mm + '/' + yyyy;

        let dayExists=false;
        if(typeof props.content.history == 'undefined'){
            //console.log('history doesnt exists')
            props.content.history = [];
        }
        for (var day in props.content.history) {
            if (props.content.history[day].x === todayFormated) {
                props.content.history[day].y = getTextWordCount();
                //console.log('day exists')
                dayExists=true;
                break; 
            }
        }
        //console.log(props.content.history)
        if(!dayExists){
            props.content.history.push({"x": todayFormated, "y": getTextWordCount()})
        }
        //console.log(props.content.history)

        // hash content
        // const contentJsonStringToSave = JSON.stringify(props.content)
        // const contentJsonStringHash = await digestMessage(contentJsonStringToSave);
        // lsSetItem('lioshi_editor_content_hash', ab2str(contentJsonStringHash));

        // add date last save in file to override dropbox file each time
        if(typeof props.content.lastsavedate == 'undefined'){
            props.content.lastsavedate = '';
        }
        props.content.lastsavedate= moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");  


        // save in localStorage
        lsSetItem(props.lsItemName, JSON.stringify(props.content));
        lsSetItem('lioshi_editor_content_lastsavedate', moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"));  
        // toast.success('Sauvegarde navigateur effectuée');

        // synchro dropbox
        var filename = props.content.titre+".json";
        // create file
        var blob = new Blob([JSON.stringify(props.content)], { type: 'application/json' });
        var file = new File([blob], filename, {type: "application/json"});

        const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
        var ACCESS_TOKEN = window.sessionStorage.getItem("lioshi_editor_access_token"); 
        
        try {
            var dbx = new Dropbox({ accessToken: ACCESS_TOKEN });
            
            if (file.size < UPLOAD_FILE_SIZE_LIMIT) { // File is smaller than 150 Mb - use filesUpload API
                dbx.filesUpload({path: '/' + file.name, contents: file, mode:'overwrite'})
                .then(function(response) {
                    toast.dismiss();
                    toast.success(<DropboxLogoToast message={<>Le fichier <b>{response.result.name}</b> est sauvegardé sur Dropbox dans /Applications/editor.lioshi.com/</>}/>);
                })
                .catch(function(error) {
                    toast.dismiss();
                    toast.error(<DropboxLogoToast message={<>{error}</>}/>);
                });
            } else {
                toast.dismiss();
                toast.error(<DropboxLogoToast message={<>Le fichier dépasse les 150Mo</>}/>);
            }
        } catch (error) {
            console.error("------------ ERROR ------------");
            console.error(error);
        }
            
        // relaunch autosave with new value of freq_save_minutes
        clearInterval(intervalAutosave);
        autosave();

        // state saved true
        handleSaved()
    };

    // set history
    const getTextWordCount = () => {
        // get headers hierarchy
        let wordCount = 0;
        const contentState = editorState.getCurrentContent()
        const entityKeys = contentState.getBlockMap()
        // get map of draft Js
        entityKeys.forEach(element => {
            // test if type is not header or blockquote, and count word
            if(element.getType()!=="header-two" && element.getType()!=="header-three" && element.getType()!=="header-four" && element.getType()!=="blockquote"){
                wordCount += element.getText().split(" ").length;
            }
        });
        //console.log('launch getTextWordCount : '+wordCount)
        return wordCount;
    }

    const [displayTextboxToolbar, setDisplayTextboxToolbar] = useState(false);
    const handleTextboxToolbarOpen = () => {
        setDisplayTextboxToolbar(true);
    };
    const handleTextboxToolbarClose = () => {
        setDisplayTextboxToolbar(false);
    };

    const [eventCtrlSBinded, setEventCtrlSBinded] = useState(false);
    if (!eventCtrlSBinded ) {
        setEventCtrlSBinded(true); // set event only once !!!!!
        document.addEventListener('keydown', (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault()
                //props.handleSave();
                //console.log('Ctrl+s')
                // test if not in configuration panel and new titre not good cause file exists
                ////console.log(document.getElementById('savebuttonConfiguration').getAttribute('disabled'));
                if(document.getElementById('savebuttonConfiguration') == null || document.getElementById('savebuttonConfiguration').getAttribute('disabled') == null){
                    document.getElementById('savebutton').click();
                } else {
                    toast.error('Erreur de chargement de l\'éditeur');
                }
                
            }
        }, false);
    }

    
    const [intervalAutosave, setIntervalAutosave] = useState(null); // use to store interval and clear it if autosave(true) is called

    const autosave = () => {
        let minuteMs = parseInt(props.content.freq_save_minutes);
        if(!minuteMs) minuteMs=5
        const interval = setInterval(() => {
            console.log('Sauvegarde toutes les '+minuteMs+' minutes ['+moment(new Date()).format("DD/MM/YYYY HH:mm:ss")+']');
            if(document.getElementById('savebuttonConfiguration') == null || document.getElementById('savebuttonConfiguration').getAttribute('disabled') == null){
                document.getElementById('savebutton').click();
            } else {
                toast.error('Erreur de chargement de l\'éditeur');
            }
        }, minuteMs*60*1000); // to prod : every X minutes
        // }, minuteMs*1000); // to test every X seconds

        setIntervalAutosave(interval)
    
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }

    useEffect(() => {
        autosave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="container" className={classes.container}>
            {/* <div id="container"></div> */}
            <Menu 
                content={props.content} 
                toc={toc} 
                setToc={setToc}
                editorState={editorState}
                handleTextboxToolbarOpen={handleTextboxToolbarOpen}
                handleTextboxToolbarClose={handleTextboxToolbarClose}
                handleSave={handleSave}
                setEditorFontSize={setEditorFontSize}
                setEditorFontFamily={setEditorFontFamily}
                setEditorWidth={setEditorWidth}
                setEditorFontColor={setEditorFontColor}
                setEditorBgColor={props.setEditorBgColor}
                dropboxFiles={props.dropboxFiles}
            />
            <Box>
                {/* {currentBlockType} */}
                <Textbox 
                    editorState={editorState}
                    EditorState={EditorState}
                    setEditorState={setEditorState}
                    //setEditorState={onEditorChange}
                    saved={saved}
                    setSaved={setSaved}
                    handleBlockClick={handleBlockClick}
                    handleSave={handleSave}
                    displayTextboxToolbar={displayTextboxToolbar}
                    editorFontSize={editorFontSize}
                    editorFontFamily={editorFontFamily}
                    editorFontColor={editorFontColor}
                    editorBgColor={props.editorBgColor}
                    editorWidth={editorWidth}
                    // getCurrentBlockType={getCurrentBlockType}
                    
                />
            </Box>
        </div>
    )
}

export default Editor;